import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAuthenticatedUser } from "../api/user";

// Fetch user action
export const fetchUser = createAsyncThunk(
    "auth/fetchUser",
    async (token, { rejectWithValue }) => {
        try {
            const response = await getAuthenticatedUser(token);
            return response; // Assuming `response.user` contains the user data
        } catch (error) {
            return rejectWithValue(error.message || "Failed to fetch user");
        }
    }
);

// Initialize state from localStorage
const initialState = {
    isAuthenticated: !!localStorage.getItem("token"), // Determines if the user is authenticated based on the token
    token: localStorage.getItem("token") || null, // Stores the token
    user: JSON.parse(localStorage.getItem("user")) || null, // Stores the user object
    error: null, // Stores any error messages
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login(state, action) {
            const { token, user } = action.payload;
            state.token = token;
            state.user = user;
            state.isAuthenticated = true;

            // Persist to localStorage
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(user));
        },
        logout(state) {
            state.token = null;
            state.user = null;
            state.isAuthenticated = false;

            // Clear localStorage
            localStorage.removeItem("token");
            localStorage.removeItem("user");
        },
        updateAlertConfiguration(state, action) {
            const { phoneNumber, slackWebhookUrl } = action.payload;
            state.user.phoneNumber = phoneNumber;
            state.user.slackWebhookUrl = slackWebhookUrl;

            // Update localStorage
            localStorage.setItem("user", JSON.stringify(state.user));
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.user = action.payload;
                state.isAuthenticated = true;


                // Update localStorage with the fetched user
                localStorage.setItem("user", JSON.stringify(action.payload));
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.error = action.payload;
                state.isAuthenticated = false;
                state.token = null;
                state.user = null;

                // Clear localStorage if fetch fails
                localStorage.removeItem("token");
                localStorage.removeItem("user");
            });
    },
});

export const { login, logout, updateAlertConfiguration } = authSlice.actions;
export default authSlice.reducer;
