import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import { exchangeTokenForJWT } from "../api/auth";
import { getAuthenticatedUser } from "../api/user";
import { login } from "../redux/authSlice";
import { Box, Button, Typography, Stack } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import MicrosoftIcon from "@mui/icons-material/Window";

const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const authenticateUser = async () => {
			const oneTimeToken = searchParams.get("token");

			if (oneTimeToken) {
				try {
					const token = await exchangeTokenForJWT(oneTimeToken);
					const user = await getAuthenticatedUser(token);
					dispatch(login({ token, user }));
					navigate("/dashboard");
				} catch (error) {
					console.error("Error during authentication:", error);
				}
			}
		};

		authenticateUser();
	}, [dispatch, navigate, searchParams]);

	const handleGoogleLogin = () => {
		window.location.href = `${process.env.REACT_APP_API_URL}/auth/login`;
	};

	const handleMicrosoftLogin = () => {
		window.location.href = `${process.env.REACT_APP_API_URL}/auth/login/microsoft`;
	};

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				backgroundColor: "#f5f5f5",
			}}>
			<Box
				sx={{
					textAlign: "center",
					padding: "40px 20px",
					borderRadius: "10px",
					backgroundColor: "#ffffff",
					boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
					width: "400px",
				}}>
				<Typography variant="h4" component="h1" sx={{ fontWeight: "bold", mb: 2, color: "#333" }}>
					Welcome to Our App
				</Typography>
				<Typography variant="body1" sx={{ mb: 3, color: "#666" }}>
					Please log in to continue
				</Typography>
				<Stack spacing={2} sx={{ px: 2 }}>
					<Button
						variant="contained"
						startIcon={<GoogleIcon />}
						onClick={handleGoogleLogin}
						sx={{
							backgroundColor: "#4285F4",
							color: "#ffffff",
							padding: "10px 20px",
							borderRadius: "5px",
							fontWeight: "bold",
							fontSize: "1rem",
							textTransform: "none",
							"&:hover": {
								backgroundColor: "#357ae8",
							},
						}}>
						Login with Google
					</Button>
					<Button
						variant="contained"
						startIcon={<MicrosoftIcon />}
						onClick={handleMicrosoftLogin}
						sx={{
							backgroundColor: "#2F2F2F",
							color: "#ffffff",
							padding: "10px 20px",
							borderRadius: "5px",
							fontWeight: "bold",
							fontSize: "1rem",
							textTransform: "none",
							"&:hover": {
								backgroundColor: "#1F1F1F",
							},
						}}>
						Login with Microsoft
					</Button>
				</Stack>
			</Box>
		</Box>
	);
};

export default Login;
