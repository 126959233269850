import { API } from ".";

// Redirect to the backend's Google login endpoint
export const loginWithGoogle = async () => {
    window.location.href = `${API.defaults.baseURL}/auth/login`;
};

// Exchange the one-time token for a JWT
export const exchangeTokenForJWT = async (oneTimeToken) => {
    const response = await API.post('/auth/exchange-token', { token: oneTimeToken });
    return response.data.token; // Return the JWT
};

