import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DateTime } from "luxon";

const EventCard = ({ event }) => {
	const [showDetails, setShowDetails] = useState(false);
	const startTime = DateTime.fromSeconds(event.start?.timestamp || 0);
	const endTime = DateTime.fromSeconds(event.end?.timestamp || 0);

	const handleJoinMeeting = e => {
		e.stopPropagation();
		if (event.meetingLink) {
			window.open(event.meetingLink, "_blank");
		}
	};

	const EventDetailsModal = () => (
		<Dialog open={showDetails} onClose={() => setShowDetails(false)} maxWidth="sm" fullWidth>
			<DialogTitle sx={styles.modalHeader}>
				{event.title}
				<IconButton onClick={() => setShowDetails(false)} sx={styles.closeButton}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<div style={styles.detailSection}>
					<h4>Time</h4>
					<p>
						{startTime.toFormat("MMMM d")}
						<br />
						{startTime.toFormat("HH:mm")} - {endTime.toFormat("HH:mm")}
					</p>
				</div>
				{event.location && (
					<div style={styles.detailSection}>
						<h4>Location</h4>
						<p>{event.location}</p>
					</div>
				)}
				{event.description && (
					<div style={styles.detailSection}>
						<h4>Description</h4>
						<p>{event.description}</p>
					</div>
				)}
				{event.attendees && (
					<div style={styles.detailSection}>
						<h4>Attendees ({event.attendees.length})</h4>
						<ul style={styles.attendeeList}>
							{event.attendees.map((attendee, index) => (
								<li key={index}>{attendee.name || attendee.email}</li>
							))}
						</ul>
					</div>
				)}
			</DialogContent>
			<DialogActions>
				{event.meetingLink && (
					<Button onClick={handleJoinMeeting} variant="contained" color="primary">
						Join Meeting
					</Button>
				)}
				<Button onClick={() => setShowDetails(false)} variant="outlined">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);

	return (
		<>
			<div style={styles.eventCard}>
				<div style={styles.dateTime}>
					<div>{startTime.toFormat("MMM d")}</div>
					<div>
						{startTime.toFormat("HH:mm")} - {endTime.toFormat("HH:mm")}
					</div>
				</div>
				<div style={styles.eventInfo}>
					<h3 style={styles.title}>{event.title}</h3>
					<div style={styles.attendees}>👥 {event.attendees?.length || 0} attendees</div>
					<div style={styles.actions}>
						{event.meetingLink && (
							<button onClick={handleJoinMeeting} style={styles.button}>
								Join Meeting
							</button>
						)}
						<button onClick={() => setShowDetails(true)} style={styles.button}>
							View Details
						</button>
					</div>
				</div>
			</div>
			<EventDetailsModal />
		</>
	);
};

const styles = {
	eventCard: {
		padding: "15px",
		marginBottom: "15px",
		backgroundColor: "#ffffff",
		borderRadius: "8px",
		boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
	},
	dateTime: {
		marginBottom: "10px",
		color: "#666",
	},
	eventInfo: {
		marginLeft: "0",
	},
	title: {
		margin: "0 0 8px 0",
		fontSize: "16px",
		fontWeight: "600",
	},
	attendees: {
		fontSize: "14px",
		color: "#666",
		marginBottom: "12px",
	},
	actions: {
		display: "flex",
		gap: "8px",
	},
	button: {
		padding: "6px 12px",
		border: "1px solid #ddd",
		borderRadius: "4px",
		backgroundColor: "#fff",
		cursor: "pointer",
		fontSize: "14px",
	},
	modalHeader: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	detailSection: {
		marginBottom: "20px",
	},
	attendeeList: {
		margin: "0",
		padding: "0 0 0 20px",
	},
	closeButton: {
		padding: "4px",
	},
};

export default EventCard;
