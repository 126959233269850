import React from "react";
import { AlertTriangle } from "lucide-react";

const ConnectCalendarButton = ({ type, isConnected, onClick, calendarAccessGranted }) => {
	const getStyles = () => {
		if (isConnected && !calendarAccessGranted) {
			return {
				...styles.connectButton,
				backgroundColor: "#fff7ed",
				color: "#9a3412",
				borderColor: "#fed7aa",
			};
		}

		return {
			...styles.connectButton,
			backgroundColor: isConnected ? "#f0fdf4" : "#f8fafc",
			color: isConnected ? "#166534" : "#475569",
		};
	};

	const getConnectionStatus = () => {
		if (isConnected) {
			if (!calendarAccessGranted) {
				return (
					<div style={styles.warningContainer}>
						<AlertTriangle className="w-4 h-4 text-orange-600" />
						<span style={styles.warningText}>Needs calendar access</span>
					</div>
				);
			}
			return "✓ Connected";
		}
		return "Connect";
	};

	return (
		<button style={getStyles()} onClick={onClick}>
			<span style={styles.buttonIcon}>{type === "microsoft" ? "📊" : "📅"}</span>
			{type === "microsoft" ? "Microsoft" : "Google"} Calendar
			<span style={styles.connectionStatus}>{getConnectionStatus()}</span>
		</button>
	);
};

const styles = {
	connectButton: {
		width: "100%",
		padding: "1rem",
		marginBottom: "0.5rem",
		border: "1px solid #e2e8f0",
		borderRadius: "8px",
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
		transition: "all 0.2s",
		fontSize: "0.875rem",
		fontWeight: 500,
	},
	buttonIcon: {
		marginRight: "0.5rem",
		fontSize: "1.25rem",
	},
	connectionStatus: {
		marginLeft: "auto",
		fontSize: "0.75rem",
		display: "flex",
		alignItems: "center",
	},
	warningContainer: {
		display: "flex",
		alignItems: "center",
		gap: "0.25rem",
	},
	warningText: {
		marginLeft: "0.25rem",
	},
};

export default ConnectCalendarButton;
