// StatCard.js
const StatCard = ({ title, value, icon }) => (
	<div style={styles.statCard}>
		<div style={styles.statIcon}>{icon}</div>
		<div style={styles.statInfo}>
			<h3 style={styles.statTitle}>{title}</h3>
			<p style={styles.statValue}>{value}</p>
		</div>
	</div>
);

const styles = {
	statCard: {
		backgroundColor: "white",
		borderRadius: "12px",
		padding: "1.5rem",
		display: "flex",
		alignItems: "center",
		gap: "1rem",
		boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
	},
	statIcon: {
		fontSize: "2rem",
		color: "#3b82f6",
	},
	statInfo: {
		flex: 1,
	},
	statTitle: {
		fontSize: "0.875rem",
		color: "#64748b",
		margin: "0 0 0.25rem",
	},
	statValue: {
		fontSize: "1.5rem",
		fontWeight: "bold",
		color: "#1e293b",
		margin: 0,
	},
};

export default StatCard;