import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../api";

// AsyncThunk to fetch events
export const fetchEvents = createAsyncThunk("events/fetchEvents", async (token, { rejectWithValue }) => {
    try {
        const response = await API.get("/events?limit=5", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data; // Assuming API returns an array of events
    } catch (error) {
        return rejectWithValue(error.response?.data || "Failed to fetch events");
    }
});

// Events slice
const eventsSlice = createSlice({
    name: "events",
    initialState: {
        events: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEvents.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchEvents.fulfilled, (state, action) => {
                state.loading = false;
                state.events = action.payload;
            })
            .addCase(fetchEvents.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const selectEvents = (state) => state.events;
export default eventsSlice.reducer;
