import React, { useState } from "react";
import { Box, Typography, Button, IconButton, TextField, Tooltip, CircularProgress } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import NotificationsIcon from "@mui/icons-material/Notifications";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SlackIcon from "@mui/icons-material/Chat";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import CustomPhoneNumberField from "./CustomPhoneNumberField";
import * as Yup from "yup";
import { updateUserProfile } from "../api/user";
import { updateAlertConfiguration } from "../redux/authSlice";

const AlertConfiguration = ({ user }) => {
	const { countryCode, phoneNumber, slackWebhookUrl } = user;
	const [isEditing, setIsEditing] = useState(false);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const token = useSelector(state => state.auth.token);

	const validationSchema = Yup.object().shape({
		slackWebhook: Yup.string().url("Invalid Slack Webhook URL"),
		phoneNumber: Yup.string().matches(/^\d{10}$/, "Invalid Phone Number"),
	});

	const handleEditClick = () => setIsEditing(true);
	const handleCancel = () => setIsEditing(false);

	const handleSubmit = async values => {
		setLoading(true);
		try {
			await updateUserProfile(
				{
					slackWebhookUrl: values.slackWebhook,
					phoneNumber: values.phoneNumber,
					countryCode: values.countryCode,
				},
				token
			);

			dispatch(
				updateAlertConfiguration({
					countryCode: values.countryCode,
					phoneNumber: values.phoneNumber,
					slackWebhookUrl: values.slackWebhook,
				})
			);

			setIsEditing(false);
		} catch (error) {
			console.error("Error updating alert configuration:", error);
			alert("Failed to update alert destinations. Please try again.");
		} finally {
			setLoading(false);
		}
	};

	const ConnectionStatus = ({ isConfigured, type }) => (
		<Box sx={styles.statusIndicator}>
			<Box
				sx={{
					...styles.statusDot,
					backgroundColor: isConfigured ? "#22c55e" : "#94a3b8",
				}}
			/>
			{type} {isConfigured ? "Connected" : "Not Connected"}
		</Box>
	);

	return (
		<Box sx={styles.container}>
			<Box sx={styles.headerSection}>
				<Box sx={styles.titleSection}>
					<NotificationsIcon sx={styles.titleIcon} />
					<Typography variant="h5" sx={styles.heading}>
						Alert Destinations
					</Typography>
				</Box>
				{!isEditing && (
					<Tooltip title="Edit Alert Configuration">
						<IconButton onClick={handleEditClick} sx={styles.editButton} color="primary">
							<EditIcon />
						</IconButton>
					</Tooltip>
				)}
			</Box>

			{!isEditing ? (
				<Box sx={styles.displayMode}>
					<Box sx={styles.connectionGrid}>
						<Box sx={styles.connectionCard}>
							<SlackIcon sx={styles.connectionIcon} />
							<Box sx={styles.connectionInfo}>
								<Typography variant="subtitle1" sx={styles.connectionTitle}>
									Slack Integration
								</Typography>
								<ConnectionStatus isConfigured={!!slackWebhookUrl} type="Webhook" />
								{slackWebhookUrl && <Typography sx={styles.webhookUrl}>{slackWebhookUrl.substring(0, 45)}...</Typography>}
							</Box>
						</Box>

						<Box sx={styles.connectionCard}>
							<WhatsAppIcon sx={styles.connectionIcon} />
							<Box sx={styles.connectionInfo}>
								<Typography variant="subtitle1" sx={styles.connectionTitle}>
									Phone Notifications
								</Typography>
								<ConnectionStatus isConfigured={!!(phoneNumber && countryCode)} type="SMS" />
								{phoneNumber && countryCode && (
									<Typography sx={styles.phoneNumber}>
										+{countryCode} {phoneNumber}
									</Typography>
								)}
							</Box>
						</Box>
					</Box>
				</Box>
			) : (
				<Formik
					initialValues={{
						slackWebhook: slackWebhookUrl || "",
						countryCode: countryCode || "",
						phoneNumber: phoneNumber || "",
					}}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}>
					{({ values, setFieldValue, errors, touched }) => (
						<Form style={styles.form}>
							<Box sx={styles.formSection}>
								<Typography variant="subtitle1" sx={styles.sectionTitle}>
									Slack Configuration
								</Typography>
								<Box sx={styles.formField}>
									<Field
										as={TextField}
										name="slackWebhook"
										label="Slack Webhook URL"
										fullWidth
										variant="outlined"
										error={touched.slackWebhook && !!errors.slackWebhook}
										helperText={<ErrorMessage name="slackWebhook" component="span" />}
									/>
								</Box>
							</Box>

							<Box sx={styles.formSection}>
								<Typography variant="subtitle1" sx={styles.sectionTitle}>
									Phone Configuration
								</Typography>
								<Box sx={styles.formField}>
									<CustomPhoneNumberField
										countryCode={values.countryCode}
										phoneNumber={values.phoneNumber}
										onChange={value => {
											setFieldValue("countryCode", value.countryCode);
											setFieldValue("phoneNumber", value.phoneNumber);
										}}
									/>
									{touched.phoneNumber && errors.phoneNumber && (
										<Typography color="error" sx={styles.errorText}>
											{errors.phoneNumber}
										</Typography>
									)}
								</Box>
							</Box>

							<Box sx={styles.formActions}>
								<Button
									type="submit"
									variant="contained"
									color="primary"
									startIcon={loading ? <CircularProgress size={20} /> : <SaveIcon />}
									disabled={loading}>
									{loading ? "Saving..." : "Save Changes"}
								</Button>
								<Button
									type="button"
									variant="outlined"
									color="secondary"
									startIcon={<CancelIcon />}
									onClick={handleCancel}
									disabled={loading}
									sx={styles.cancelButton}>
									Cancel
								</Button>
							</Box>
						</Form>
					)}
				</Formik>
			)}
		</Box>
	);
};

const styles = {
	container: {
		padding: "24px",
		backgroundColor: "#ffffff",
		borderRadius: "12px",
		boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
	},
	headerSection: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: "24px",
	},
	titleSection: {
		display: "flex",
		alignItems: "center",
		gap: "12px",
	},
	titleIcon: {
		color: "#6366f1",
		fontSize: "28px",
	},
	heading: {
		fontWeight: 600,
		color: "#1e293b",
		margin: 0,
	},
	connectionGrid: {
		display: "grid",
		gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
		gap: "20px",
		marginTop: "16px",
	},
	connectionCard: {
		display: "flex",
		alignItems: "flex-start",
		gap: "16px",
		padding: "20px",
		backgroundColor: "#f8fafc",
		borderRadius: "8px",
		border: "1px solid #e2e8f0",
	},
	connectionIcon: {
		fontSize: "32px",
		color: "#6366f1",
	},
	connectionInfo: {
		flex: 1,
	},
	connectionTitle: {
		fontWeight: 600,
		color: "#1e293b",
		marginBottom: "8px",
	},
	statusIndicator: {
		display: "flex",
		alignItems: "center",
		gap: "8px",
		fontSize: "0.875rem",
		color: "#64748b",
	},
	statusDot: {
		width: "8px",
		height: "8px",
		borderRadius: "50%",
	},
	webhookUrl: {
		fontSize: "0.875rem",
		color: "#64748b",
		marginTop: "8px",
		wordBreak: "break-all",
	},
	phoneNumber: {
		fontSize: "0.875rem",
		color: "#64748b",
		marginTop: "8px",
	},
	form: {
		display: "flex",
		flexDirection: "column",
		gap: "24px",
	},
	formSection: {
		display: "flex",
		flexDirection: "column",
		gap: "16px",
	},
	sectionTitle: {
		fontWeight: 600,
		color: "#475569",
	},
	formField: {
		width: "100%",
	},
	formActions: {
		display: "flex",
		gap: "12px",
		marginTop: "16px",
	},
	cancelButton: {
		color: "#64748b",
		borderColor: "#cbd5e1",
		"&:hover": {
			borderColor: "#94a3b8",
			backgroundColor: "#f8fafc",
		},
	},
	errorText: {
		fontSize: "0.875rem",
		marginTop: "6px",
	},
};

export default AlertConfiguration;
