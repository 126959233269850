import React, { useState, useEffect } from "react";
import { Box, TextField, Autocomplete } from "@mui/material";

const countries = [
	{ code: "CA", label: "Canada", phone: "1", flag: "🇨🇦" },
	{ code: "US", label: "United States", phone: "1", flag: "🇺🇸" },
	{ code: "GB", label: "United Kingdom", phone: "44", flag: "🇬🇧" },
	{ code: "PK", label: "Pakistan", phone: "92", flag: "🇵🇰" },
];

const CustomPhoneNumberField = ({ countryCode, phoneNumber, onChange }) => {
	const [selectedCountry, setSelectedCountry] = useState(countries.find(c => c.phone === countryCode) || countries[0]);
	const [localPhoneNumber, setLocalPhoneNumber] = useState(phoneNumber || "");

	// Handle country change
	const handleCountryChange = (event, newValue) => {
		setSelectedCountry(newValue || countries[0]);
	};

	// Handle phone number change
	const handlePhoneNumberChange = event => {
		setLocalPhoneNumber(event.target.value);
	};

	// Notify parent component of changes
	useEffect(() => {
			onChange({
				countryCode: selectedCountry.phone,
				phoneNumber: localPhoneNumber,
			});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCountry, localPhoneNumber]);

	return (
		<Box display="flex" gap={2} alignItems="center">
			{/* Country Code Selector */}
			<Autocomplete
				options={countries}
				value={selectedCountry}
				onChange={handleCountryChange}
				getOptionLabel={option => `${option.flag} +${option.phone}`}
				renderOption={(props, option) => (
					<Box component="li" {...props} sx={{ display: "flex", alignItems: "center", gap: 1 }}>
						<span>{option.flag}</span>+{option.phone}
					</Box>
				)}
				isOptionEqualToValue={(option, value) => option.code === value.code}
				sx={{ width: "150px" }}
				renderInput={params => <TextField {...params} label="Country" />}
				disableClearable // Disable the "clear" button
				freeSolo={false} // Disable typing
			/>

			{/* Phone Number Input */}
			<TextField label="Phone Number" value={localPhoneNumber} onChange={handlePhoneNumberChange} fullWidth />
		</Box>
	);
};

export default CustomPhoneNumberField;
