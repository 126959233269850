import { API } from ".";

// Fetch the user's profile using the JWT
export const getAuthenticatedUser = async (jwt) => {
    const response = await API.get('/user/me', {
        headers: { Authorization: `Bearer ${jwt}` },
    });
    return response.data;
};

// Update User Profile API
export const updateUserProfile = async (data, token) => {
    try {
        const response = await API.put("/user/me", data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data; // Return the API response
    } catch (error) {
        throw error.response?.data || error; // Throw error for handling in the caller
    }
};

export const getLoginUrl = async (token, provider) => {
    const response = await API.get(`/user/connect/${provider}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response.data;
};

